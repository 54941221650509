<template>
  <div>
    <mobile-access :is-modal="true" :mobile-app-type="'virtual-phone'" />
    <b-row align-h="end">
      <b-button
        v-if="!modalSubmissionInProgress"
        aria-label="Finish button"
        class="next-button"
        variant="info"
        size="lg"
        @click="finishSetup"
      >
        Finish
      </b-button>
      <ct-centered-spinner v-else />
    </b-row>
  </div>
</template>

<script>
import MobileAccess from '@/components/shared/MobileAccess'
import CtCenteredSpinner from '../../shared/CtCenteredSpinner'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Step4',

  components: { MobileAccess, CtCenteredSpinner },
  computed: {
    ...mapGetters('virtualPhones', ['modalSubmissionInProgress']),
  },
  methods: {
    ...mapActions('virtualPhones', ['setMobileAccessFinished', 'submitModalDecisions']),

    async finishSetup() {
      await this.setMobileAccessFinished(true)
      await this.submitModalDecisions()
      this.$bvModal.hide('virtual-phone-setup-modal')
    },
  },
}
</script>

<style scoped>

</style>
